import { render, staticRenderFns } from "./certiticateProHireXpForm.vue?vue&type=template&id=6d3863ce&"
import script from "./certiticateProHireXpForm.vue?vue&type=script&lang=js&"
export * from "./certiticateProHireXpForm.vue?vue&type=script&lang=js&"
import style0 from "./certiticateProHireXpForm.vue?vue&type=style&index=0&id=6d3863ce&prod&lang=less&"
import style1 from "./certiticateProHireXpForm.vue?vue&type=style&index=1&id=6d3863ce&prod&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports