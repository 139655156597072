<template>
  <modify-apply-block
    :column-list="columnList"
    ref="rew_p"
    :tableActions="tableActions"
    :dataFileParm="{pDirPath: '/rew/'}"
    :display="display"
    :form-parms-add="formParmsAdd"
    :initAddFormData="initAddFormData"
    :isReal="isReal"
    block-name="奖励信息"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    @completeness="completeness"
    ref-name="rew"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  rewPunRequest
} from '../../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await rewPunRequest.get({
          type: 'rew',
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await rewPunRequest.getEdit({
          type: 'rew',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await rewPunRequest.del(parm.id)
        } else {
          return await rewPunRequest.update(parm.id, parm)
        }
      } else {
        return await rewPunRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await rewPunRequest.add(parm)
      } else {
        return await rewPunRequest.addEdit(parm)
      }
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'rew'
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['rew_p'].save()
    },
    async submit () {
      await this.$refs['rew_p'].submit()
    },
    testEdited () {
      return this.$refs['rew_p'].testEdited()
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '奖励类型',
          selectDatas: this.$store.getters.selectDatas['rew_type_list'],
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '奖励名称',
          key: 'pzwj',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '奖励批准日期',
          key: 'pzsj'
        },
        {
          type: 'input',
          label: '奖励批准机关名称',
          key: 'pzdw'
        },
        {
          type: 'select',
          label: '授予荣誉称号的级别',
          selectDatas: this.$store.getters.selectDatas['rew_level_list'],
          key: 'level'
        },
        {
          type: 'input',
          label: '奖励原因',
          key: 'reson'
        },
        {
          type: 'datePicker',
          label: '奖励撤销日期',
          key: 'jcsj'
        },
        {
          type: 'input',
          label: '奖励撤销原因',
          key: 'jcyy'
        },
        {
          type: 'input',
          label: '奖励文件文号',
          key: 'pzwjh'
        }]
        return data
      }
    },
    columnList () {
      return [{
        title: '奖励类型',
        field: 'title',
        sort: true
      },
      {
        title: '奖励名称',
        field: 'pzwj',
        sort: true
      },
      {
        title: '奖励批准日期',
        field: 'pzsj',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.pzsj ? rowData.pzsj.slice(0, 10) : '-'
        }
      },
      {
        title: '奖励批准机关名称',
        field: 'pzdw',
        sort: true
      },
      {
        title: '授予荣誉称号的级别',
        field: 'level',
        sort: true
      },
      {
        title: '奖励原因',
        field: 'reson',
        sort: true
      },
      {
        title: '奖励文件文号',
        field: 'pzwjh',
        sort: true
      },
      {
        title: '奖励撤销日期',
        field: 'jcsj',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.jcsj ? rowData.jcsj.slice(0, 10) : '-'
        }
      },
      {
        title: '奖励撤销原因',
        sort: true,
        field: 'jcyy'
      }]
    }
  }
}
</script>
