<template>
  <div v-if="display">
    <fm-title title-text="工作信息"></fm-title>
    <data-form
      :source-data="dealSourceData"
      :edit-data="formData"
      :form-parms="formParms"
      @formChange="formChange"
      :wait-data="waitData"
      :is-absolut="isAbsolut"
      :wait-keys="waitKeys">
    </data-form>
  </div>
</template>

<script>
import {
  dataAppleRequest,
  workerRequest
} from '../../../../api'

import {
  dateOperating
} from '@/fmlib'

import DataForm from './dataForm'
// import OrgSelect from '@/views/org/org/orgSelect'
// import OrgMultipleSelect from '@/views/org/org/orgMultipleSelect'

export default {
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: Number
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DataForm
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      changeData: {},
      isChange: false,
      dealSourceData: {},
      data: {}
    }
  },
  computed: {
    completeness () {
      if (!this.formData) {
        return 0
      }
      return this.formParms.filter(v => v.type !== 'null' && this.formData[v.key]).length / this.formParms.filter(v => v.type !== 'null').length
    },
    sourceData () {
      return this.data && this.data.sourceInfo ? this.data.sourceInfo : null
    },
    editData () {
      let data = this.data && this.data.editInfo ? this.data.editInfo : null
      if (data) {
        data.maritalStatus = this.sourceData ? this.sourceData.maritalStatus : null
      }
      return data
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    formParms () {
      let data1 = [{
        type: 'input',
        label: '工号',
        key: 'code'
      },
      {
        type: 'input',
        label: '机构名称',
        key: 'orgName',
        fmDisabled: this.orgNameDisabled
      },
      {
        type: 'input',
        label: '机构名称2',
        show: () => {
          return this.needOrgName2
        },
        key: 'zg',
        fmDisabled: this.orgName2Disabled
      },
      // {
      //   type: 'cmp',
      //   cmp: OrgSelect,
      //   label: '所属职务',
      //   key: 'positionId',
      //   format: (data) => {
      //     let data1 = this.positionList.find(v => v.key === data.positionId)
      //     return data1 ? data1.label : null
      //   },
      //   compare: (data) => {
      //     return data.positionId
      //   }
      // },
      // {
      //   type: 'cmp',
      //   cmp: OrgMultipleSelect,
      //   label: '兼职职务',
      //   key: 'positionIds',
      //   format: (data) => {
      //     return Array.isArray(data.positionDatas) ? data.positionDatas.map(v => v.name).join(',') : ''
      //   },
      //   compare: (data) => {
      //     return Array.isArray(data.positionIds) ? data.positionIds.join(',') : data.positionIds
      //   }
      // },
      {
        type: 'select',
        label: '是否在编',
        key: 'type',
        selectDatas: [{key: '在编', label: '在编'}, {key: '非在编', label: '非在编'}]
      },
      {
        type: 'select',
        label: '在岗状态',
        key: 'jobTypeC',
        selectDatas: this.workerZgStatus
      },
      {
        type: 'input',
        label: '工龄',
        key: 'maritalStatus',
        fmDisabled: true
      },
      {
        type: 'datePicker',
        label: '参加工作时间',
        key: 'correctionDate',
        format: (data) => {
          return data.correctionDate ? dateOperating.computeDay({days: 0, date: data.correctionDate, format: 'yy-mm-dd'}) : null
        }
      },
      {
        type: 'datePicker',
        label: '进入本单位时间',
        key: 'entryDate',
        format: (data) => {
          return data.entryDate ? dateOperating.computeDay({days: 0, date: data.entryDate, format: 'yy-mm-dd'}) : null
        }
      },
      {
        type: 'select',
        label: '人员类别',
        key: 'jobType',
        selectDatas: this.workerJobTypes
      },
      {
        type: 'select',
        label: '入职方式',
        key: 'employmentForm',
        selectDatas: this.employmentForms
      },
      {
        type: 'select',
        label: '离职方式',
        key: 'discipline',
        fmDisabled: this.workerApplyEditType1,
        selectDatas: this.$store.getters.selectDatas['worker_resign_list']
      },
      {
        type: 'datePicker',
        label: '离职时间',
        key: 'departTime',
        format: (data) => {
          return data.departTime ? dateOperating.computeDay({days: 0, date: data.departTime, format: 'yy-mm-dd'}) : null
        }
      },
      {
        type: 'select',
        label: '是否专业技术人员',
        key: 'address',
        selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}]
      },
      {
        type: 'input',
        label: '专业技术人员专业名称',
        key: 'shuangjiantiao',
        show: (data) => {
          return data.address === '是'
        }
      },
      {
        type: 'select',
        label: '专业技术人员专业类别',
        key: 'email',
        show: (data) => {
          return data.address === '是'
        },
        selectDatas: this.zyjsryzylb
      },
      {
        type: 'select',
        label: '是否卫生专业技术人员',
        key: 'jobTitle',
        show: (data) => {
          return data.address === '是'
        },
        selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}]
      }]
      return data1
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    employmentForms () {
      return this.$store.getters.selectDatas['employment_form']
    },
    zyjsryzylb () {
      return this.$store.getters.selectDatas['zyjsryzylb']
    },
    workerJobTypes () {
      return this.$store.getters.selectDatas['worker_job_type']
    },
    workerZgStatus () {
      return this.$store.getters.selectDatas['worker_zg_status']
    },
    // positionList () {
    //   return this.$store.getters.positionList
    // },
    orgNameDisabled () {
      return this.$store.getters.sysConfig['orgNameDisabled']
    },
    orgName2Disabled () {
      return this.$store.getters.sysConfig['orgName2Disabled']
    },
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    }
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    },
    editData: {
      handler () {
        this.updateFormData()
      },
      immediate: true,
      deep: true
    },
    sourceData: {
      handler () {
        this.updateSourceData()
        this.updateFormData()
      },
      immediate: true,
      deep: true
    }
  },
  async created () {
    // await this.$store.dispatch('loadPositionList')
    await this.$store.dispatch('loadOrgTree')
    this.loadData()
  },
  methods: {
    async loadData () {
      if (this.workerId) {
        let data = []
        if (this.isReal) {
          data = await workerRequest.get({
            workerId: this.workerId
          })
          data = data.map(v => {
            return {
              sourceInfo: v
            }
          })
        } else {
          data = await workerRequest.getEdit({
            workerId: this.workerId
          })
        }
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    updateSourceData () {
      // let data1 = JSON.parse(JSON.stringify(this.sourceData))
      // if (data1 && data1.positionIds && !Array.isArray(data1.positionIds)) {
      //   data1.positionIds = data1.positionIds.split(',').map(v => Number(v))
      // }
      this.dealSourceData = JSON.parse(JSON.stringify(this.sourceData))
    },
    updateFormData () {
      let data1 = JSON.parse(JSON.stringify(this.editData || this.sourceData))
      // if (data1) {
      //   if (data1.positionIds && !Array.isArray(data1.positionIds)) {
      //     data1.positionIds = data1.positionIds.split(',').map(v => Number(v))
      //   } else {
      //     data1.positionIds = []
      //   }
      // }
      this.formData = data1
    },
    testEdited () {
      return this.isChange
    },
    async save () {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      // if (saveData && Array.isArray(saveData.positionIds)) {
      //   saveData.positionIds = saveData.positionIds.join(',')
      // }
      if (this.isReal) {
        await workerRequest.update(saveData.id, saveData)
      } else {
        await workerRequest.saveEdit(Object.assign({}, saveData, {
          dataId: saveData.id,
          id: undefined
        }))
      }
      this.isChange = false
      this.loadData()
      return true
    },
    async submit () {
      let data = await this.save()
      if (data && !this.isReal) {
        await dataAppleRequest.submitByWorkerId(this.workerId, {dataType: null})
        this.loadData()
      }
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>
