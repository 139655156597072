<template>
  <modify-apply-block
    :column-list="columnList"
    ref="his_work_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    block-name="工作记录"
    :tableActions="tableActions"
    :dealDataInfo="dealDataInfo"
    @completeness="completeness"
    :is-real="isReal"
    ref-name="his_work"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  hisWorkRequest
} from '../../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await hisWorkRequest.get({
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await hisWorkRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await hisWorkRequest.del(parm.id)
        } else {
          return await hisWorkRequest.update(parm.id, parm)
        }
      } else {
        return await hisWorkRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await hisWorkRequest.add(parm)
      } else {
        return await hisWorkRequest.addEdit(parm)
      }
    },
    dealDataInfo (data) {
      if (data.isOver === '否') {
        data.endTime = null
      }
      return data
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['his_work_p'].save()
    },
    async submit () {
      await this.$refs['his_work_p'].submit()
    },
    testEdited () {
      return this.$refs['his_work_p'].testEdited()
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '工作单位',
          key: 'orgName',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '担任职务',
          key: 'jobTitle',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否已结束',
          key: 'isOver',
          selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '结束时间',
          key: 'endTime',
          show: (formData) => {
            return formData.isOver === '是'
          },
          check: {
            required: true
          }
        }]
        return data
      }
    },
    columnList () {
      return [{
        title: '工作单位',
        field: 'orgName',
        sort: true
      },
      {
        title: '担任职务',
        field: 'jobTitle',
        sort: true
      },
      {
        title: '开始时间',
        field: 'startTime',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-'
        }
      },
      {
        title: '结束时间',
        field: 'endTime',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-'
        }
      }]
    }
  }
}
</script>
