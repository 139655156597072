<template>
  <a href="javascript:;" @click="clickDom" class="circle" :style="circleStyle">
    <div class="left-c" :style="leftBorderRadius">
      <div class="left-content" :style="leftBorderRadiusContent"></div>
    </div>
    <div class="right-c">
      <div class="right-content" :style="rightBorderRadiusContent"></div>
    </div>
    <div class="c-label" @click="clickDom" :style="cLabelStyle">{{(rate * 100).toFixed(0)}}</div>
  </a>
</template>

<script>
export default {
  props: {
    size: {
      type: Number
    },
    borderWidth: {
      type: Number
    },
    rate: {
      type: Number,
      default: 0
    }
  },
  methods: {
    clickDom () {
      this.$emit('clickDom')
    }
  },
  computed: {
    circleStyle () {
      return {
        width: this.size + 'px',
        height: this.size + 'px'
      }
    },
    cLabelStyle () {
      return {
        width: 'calc(100% - 2 * ' + this.borderWidth + 'px)',
        height: 'calc(100% - 2 * ' + this.borderWidth + 'px)',
        top: this.borderWidth + 'px',
        left: this.borderWidth + 'px'
      }
    },
    leftBorderRadius () {
      return {
        'border-top-left-radius': (this.size / 2) + 'px',
        'border-bottom-left-radius': (this.size / 2) + 'px'
      }
    },
    leftBorderRadiusContent () {
      let jd = this.rate < 0.5 ? 0 : 360 * (this.rate - 0.5)
      return {
        'border-top-left-radius': (this.size / 2) + 'px',
        'border-bottom-left-radius': (this.size / 2) + 'px',
        'transform': 'rotate(' + jd + 'deg)'
      }
    },
    rightBorderRadius () {
      return {
        'border-top-right-radius': (this.size / 2) + 'px',
        'border-bottom-right-radius': (this.size / 2) + 'px'
      }
    },
    rightBorderRadiusContent () {
      let jd = this.rate > 0.5 ? 180 : 360 * this.rate
      return {
        'border-top-right-radius': (this.size / 2) + 'px',
        'border-bottom-right-radius': (this.size / 2) + 'px',
        'transform': 'rotate(' + jd + 'deg)'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@color-no:#DBECF8;
@color-a:#FF788F;
.circle {
  position:relative;
  border-radius: 100%;
  background: @color-a;
  margin:0 auto;
}
.left-c {
  position: absolute;
  left: 0;
  height: 100%;
  width: 50%;
  overflow:hidden;
}
.left-content {
  width: 100%;
  height: 100%;
  background: @color-no;
  transform-origin:right center;
}
.right-c {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  overflow:hidden;
}
.right-content {
  width: 100%;
  height: 100%;
  transform-origin:left center;
  background: @color-no;
}
.c-label {
  position:absolute;
  display: flex;
  align-items:center;
  justify-content:center;
  background:#fff;
  border-radius:100%;
  font-weight: 400;
  font-size: 12px;
  color:@color-a;;
}
</style>

<style lang="less">
@color-a:#F4628F;
@color-b:#2F5BEA;
@color-c:#6C573C;
.pink-theme {
  .theme-step {
    .c-label {color: @color-a;}
    .circle {background: @color-a;}
  }
}
.blue-theme {
  .theme-step {
    .c-label {color: @color-b;}
    .circle {background: @color-b;}
  }
}
.yellow-theme {
  .theme-step {
    .c-label {color: @color-c;}
    .circle {background: @color-c;}
  }
}
</style>
