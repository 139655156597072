<template>
  <modify-apply-block
    :column-list="columnList"
    ref="worker_certificate_pro_hire_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    :table-actions="tableActions"
    :isReal="isReal"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :dataFileParm="{pDirPath: '/worker_certificate/'}"
    block-name="专业技术职务聘任证书"
    :init-add-form-data="initAddFormData"
    @completeness="completeness"
    ref-name="worker_certificate_pro_hire"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'
import CertiticateProHireXpForm from '../../../worker/worker/certiticateProHireXpForm'
import CertiticateProHireXpList from '../../../worker/worker/certiticateProHireXpList'

import {
  workerCertificateRequest
} from '../../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.$store.dispatch('loadJobTitleList')
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerCertificateRequest.get({
          type: 'pro_hire',
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerCertificateRequest.getEdit({
          type: 'pro_hire',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerCertificateRequest.del(parm.id)
        } else {
          return await workerCertificateRequest.update(parm.id, parm)
        }
      } else {
        return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerCertificateRequest.add(parm)
      } else {
        return await workerCertificateRequest.addEdit(parm)
      }
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'pro_hire'
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_certificate_pro_hire_p'].save()
    },
    async submit () {
      await this.$refs['worker_certificate_pro_hire_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_certificate_pro_hire_p'].testEdited()
    }
  },
  computed: {
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    jobTitleList () {
      return this.$store.getters.jobTitleList
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '专业',
          key: 'hirePro'
        },
        {
          type: 'input',
          label: '聘任单位',
          key: 'hireOrg'
        },
        {
          type: 'input',
          label: '聘任职务',
          key: 'hireWorker'
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.jobTitleTypes,
          key: 'xlmc'
        },
        {
          type: 'select',
          label: '岗位等级',
          getSelectDatas: (fData) => {
            let data = []
            if (fData && fData.xlmc) {
              data = this.jobTitleList.filter(v => v.data.type === fData.xlmc).map((v) => {
                return {
                  key: v.data.title,
                  label: v.data.title
                }
              })
            }
            return data
          },
          key: 'mType'
        },
        {
          type: 'datePicker',
          label: '聘期起始日期',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        },
        {
          type: 'cmp',
          label: '续聘记录',
          formatCmp: CertiticateProHireXpList,
          key: 'updateLog',
          cmp: CertiticateProHireXpForm
        }]
        return data
      }
    },
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件'
      },
      {
        key: 'table-expand-edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnList () {
      return [{
      //   title: '编号',
      //   field: 'id',
      //   sort: true,
      //   fixed: 'left',
      //   width: 100
      // },
      // {
        title: '证书编码',
        field: 'code',
        sort: true
      },
      {
        title: '专业',
        field: 'hirePro',
        sort: true
      },
      {
        title: '聘任单位',
        field: 'hireOrg',
        sort: true
      },
      {
        title: '岗位类型',
        field: 'xlmc',
        sort: true
      },
      {
        title: '岗位等级',
        field: 'mType',
        sort: true
      },
      {
        title: '聘任职务',
        field: 'hireWorker',
        sort: true
      },
      {
        title: '聘期起始日期',
        field: 'hireStartDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '聘期终止日期',
        field: 'hireEndDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
        }
      },
      {
        title: '扫描件状态',
        field: 'imgStatusText',
        sort: true
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        width: 180,
        sort: true,
        dataType: Date
      }]
    }
  }
}
</script>
