<template>
  <div class="zyzs-bjjl">
    <div v-for="(item, index) in dataList" :key="index">
      <div class="jl-item">
        <div class="log-info" v-for="c in formParms" :key="c.key">
          <div>{{c.label}}:</div>
          <span>{{c.key === 'endDate' ? (item.endDate ? item.endDate.slice(0,4) : '') : item[c.key]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'input',
        label: '继续教育项目名称',
        key: 'xmmc'
      },
      // {
      //   type: 'input',
      //   label: '学习形式',
      //   key: 'xxxs'
      // },
      // {
      //   type: 'datePicker',
      //   label: '开始时间',
      //   key: 'startDate'
      // },
      {
        type: 'yearPicker',
        label: '所属年份',
        key: 'endDate'
      },
      {
        type: 'input',
        label: '总学时',
        key: 'xsTotal'
      },
      {
        type: 'input',
        label: '总学分',
        key: 'xfTotal'
      // },
      // {
      //   type: 'input',
      //   label: '认定单位',
      //   key: 'rddw'
      // },
      // {
      //   type: 'datePicker',
      //   label: '审核时间',
      //   key: 'shsj'
      // },
      // {
      //   type: 'select',
      //   label: '完成情况',
      //   key: 'wcqk',
      //   selectDatas: [{key: '已完成', label: '已完成'}, {key: '未完成', label: '未完成'}]
      // },
      // {
      //   type: 'input',
      //   label: '验证单位',
      //   key: 'yzdw'
      }]
    },
    dataList () {
      return this.value ? JSON.parse(this.value) : []
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  width: 16rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}
.log-info {
  display: flex;
  min-height: 1.4rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>