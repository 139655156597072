<template>
  <div class="assets-type-form-dev">
    <a :class="wrapClass" class="assets-type-form" href="javascript:;">{{valueLabel}}
      <fm-btn size="small" style="margin-left: 2rem" @click="modalAssetsType = true">详细</fm-btn>
    </a>
    <fm-modal v-model="modalAssetsType" width="800px" theme="mh-blackt">
      <div style="width: 100%;height:70vh;">
        <fm-title title-text="变更记录"
          :title-menus="[{key: 'first', label: '新增首次注册'}, {key: 'goon', label: '新增延续注册'}, {key: 'change', label: '新增变更注册'}]"
          @clickTitleMenu="clickTitleMenu">
        </fm-title>
        <div class="zyzs-bjjl">
          <div v-for="item in updateLogList" :key="item.id">
            <div class="jl-item" v-if="item.type === 'change'">
              <fm-title title-text="变更注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
              <div class="log-info">
                <div>变更日期:</div>
                <span>{{item.changeDate}}</span>
              </div>
              <div class="log-info">
                <div>变更事项:</div>
                <span>{{item.changeContent}}</span>
              </div>
            </div>
            <div class="jl-item" v-if="item.type === 'goon'">
              <fm-title title-text="延续注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
              <div class="log-info">
                <div>注册日期:</div>
                <span>{{item.registDate}}</span>
              </div>
              <div class="log-info">
                <div>注册有效期至:</div>
                <span>{{item.registEndDate}}</span>
              </div>
            </div>
            <div class="jl-item" v-if="item.type === 'first'">
              <fm-title title-text="首次注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
              <div class="log-info">
                <div>注册日期:</div>
                <span>{{item.registDate}}</span>
              </div>
              <div class="log-info">
                <div>注册有效期至:</div>
                <span>{{item.registEndDate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fm-modal>
    <fm-form-dialog
      :form-title="logType === 'goon' ? '延续注册' : (logType === 'change' ? '变更注册' : '首次注册')"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="hisLogData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'ChangeLogForm',
  data () {
    return {
      openDialog: false,
      modalAssetsType: false,
      onError: false,
      logType: null,
      hisLogData: {},
      updateLogList: []
    }
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    formParms () {
      let yx = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'registDate'
      },
      {
        type: 'datePicker',
        label: '注册有效期至',
        key: 'registEndDate'
      }]
      let bg = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'changeDate'
      },
      {
        type: 'input',
        label: '变更事项',
        key: 'changeContent'
      }]
      return this.logType === 'change' ? bg : yx
    },
    valueLabel () {
      let text = '无记录'
      if (this.updateLogList.length > 0) {
        text = this.updateLogList.length + '条记录'
      }
      return text
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  watch: {
    value: {
      handler (data) {
        this.updateLogList = data ? JSON.parse(data) : []
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async formSubmit (parm, resolve) {
      for (let key in parm) {
        if (parm[key] instanceof Date) {
          parm[key] = dateOperating.computeDay({days: 0, date: parm[key], format: 'yy-mm-dd'})
        }
      }
      parm.type = this.logType
      let newData = []
      if (parm.id) {
        this.updateLogList.forEach((v) => {
          if (v.id === parm.id) {
            newData.push(parm)
          } else {
            newData.push(v)
          }
        })
      } else {
        newData = this.updateLogList.map(v => v)
        parm.id = new Date().getTime()
        newData.push(parm)
      }
      newData.sort((a, b) => {
        let datea = new Date(a.registDate || a.changeDate)
        let dateb = new Date(b.registDate || b.changeDate)
        return dateb - datea
      })
      this.change(JSON.stringify(newData))
      this.openDialog = false
      resolve()
    },
    clickTitleMenu (key) {
      this.hisLogData = null
      this.logType = key
      this.openDialog = true
    },
    async clickTitleMenuLog (key, data) {
      this.logType = data.type
      if (key === 'edit') {
        this.hisLogData = Object.assign({}, data)
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该' + (this.logType === 'change' ? '变更注册' : (this.logType === 'goon' ? '延续注册' : '首次注册')) + '吗?'})
        if (!result) {
          return
        }
        this.change(JSON.stringify(this.updateLogList.filter(v => v.id !== data.id)))
      }
    },
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    change (data) {
      this.$emit('change', data)
      this.verifier()
    }
  }
}
</script>

<style lang="less" scope>
.assets-type-form {
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  // border: 1px solid #dcdee2;
  align-items: center;
  box-shadow: 0 0 0px 3px transparent;
  font-size: 14px;
  color: #515a6e;
  padding: 0 8px;
  height: 30px;
}
.assets-type-form-dev {
  // 报错设置
    .verifier-error {
      border-color: red;
    }
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.zyzs-bjjl {
  height: calc(100% - 3rem);
  overflow-y: auto;
}
.log-info-t {
  color: #515a6e;
}
</style>