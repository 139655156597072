<template>
  <div class="xp-dev">
    <a :class="wrapClass" class="assets-type-form" href="javascript:;">{{valueLabel}}
      <fm-btn size="small" style="margin-left: 2rem" @click="showDetail = true">详细</fm-btn>
    </a>
    <fm-modal v-model="showDetail" v-if="showDetail" width="800px" theme="mh-blackt">
      <div style="width: 100%;">
        <fm-title title-text="专业技术职务变动情况" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'add', label: '新增'}]"></fm-title>
        <fm-table-new
          :simple-filter="true"
          export-file-name="专业技术职务变动情况"
          :columns="columns"
          border="row"
          :data-list="dataList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="专业技术职务变动情况"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'ChangDataForm',
  components: {
    TableActions
  },
  data () {
    return {
      openDialog: false,
      showDetail: false,
      onError: false,
      chooseData: {},
      dataList: []
    }
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columns () {
      let data = this.formParms.map(v => {
        let item = {
          title: v.label,
          field: v.key
        }
        if (v.type === 'datePicker') {
          item.render = (h, rowData) => {
            return h('div', rowData && rowData[v.key] ? rowData[v.key].slice(0, 10) : '-')
          }
        }
        return item
      })
      data.push({
        title: '操作',
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      })
      return data
    },
    formParms () {
      return [{
        type: 'input',
        label: '专业技术职务名称',
        key: 'zyjszwmc'
      },
      {
        type: 'datePicker',
        label: '任职开始时间',
        key: 'startDate'
      },
      {
        type: 'datePicker',
        label: '任职结束时间',
        key: 'endDate'
      }]
    },
    valueLabel () {
      let text = '无记录'
      if (this.dataList.length > 0) {
        text = this.dataList.length + '条记录'
      }
      return text
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  watch: {
    value: {
      handler (data) {
        this.dataList = data ? JSON.parse(data) : []
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async formSubmit (parm, resolve) {
      for (let key in parm) {
        if (parm[key] instanceof Date) {
          parm[key] = dateOperating.computeDay({days: 0, date: parm[key], format: 'yy-mm-dd'})
        }
      }
      let newData = []
      if (parm.id) {
        this.dataList.forEach((v) => {
          if (v.id === parm.id) {
            newData.push(parm)
          } else {
            newData.push(v)
          }
        })
      } else {
        newData = this.dataList.map(v => v)
        parm.id = new Date().getTime()
        newData.push(parm)
      }
      this.change(JSON.stringify(newData))
      this.openDialog = false
      resolve()
    },
    clickTitleMenu () {
      this.chooseData = null
      this.openDialog = true
    },
    async tableAction (parm) {
      let key = parm.action
      let data = parm.data
      if (key === 'edit') {
        this.chooseData = Object.assign({}, data)
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该专业技术职务变动情况吗？'})
        if (!result) {
          return
        }
        this.change(JSON.stringify(this.dataList.filter(v => v.id !== data.id)))
      }
    },
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    change (data) {
      this.$emit('change', data)
      this.verifier()
    }
  }
}
</script>

<style lang="less">
.xp-dev {
  .fm-form-item {
    width: 100% !important;
  }
}
</style>

<style lang="less" scope>
.assets-type-form-dev {
  // 报错设置
    .verifier-error {
      border-color: red;
    }
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.zyzs-bjjl {
  height: calc(100% - 3rem);
  overflow-y: auto;
}
.log-info-t {
  color: #515a6e;
}
</style>