<template>
  <div class="zyzs-bjjl">
    <div v-for="item in updateLogList" :key="item.id">
      <div class="jl-item" v-if="item.type === 'change'">
        <span>变更注册</span>
        <div class="log-info">
          <div>变更日期:</div>
          <span>{{item.changeDate}}</span>
        </div>
        <div class="log-info">
          <div>变更事项:</div>
          <span>{{item.changeContent}}</span>
        </div>
      </div>
      <div class="jl-item" v-if="item.type === 'goon'">
        <span>延续注册</span>
        <div class="log-info">
          <div>注册日期:</div>
          <span>{{item.registDate}}</span>
        </div>
        <div class="log-info">
          <div>注册有效期至:</div>
          <span>{{item.registEndDate}}</span>
        </div>
      </div>
      <div class="jl-item" v-if="item.type === 'first'">
        <span>首次注册</span>
        <div class="log-info">
          <div>注册日期:</div>
          <span>{{item.registDate}}</span>
        </div>
        <div class="log-info">
          <div>注册有效期至:</div>
          <span>{{item.registEndDate}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  computed: {
    updateLogList () {
      return this.value ? JSON.parse(this.value) : []
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  width: 16rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}
.log-info {
  display: flex;
  min-height: 1.4rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>