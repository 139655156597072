<template>
  <modify-apply-block
    :column-list="columnList"
    :display="display"
    data-type="worker_edu_continue"
    :table-actions="tableActions"
    ref="worker_edu_continue_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    block-name="继续教育"
    :is-real="isReal"
    :is-absolut="isAbsolut"
    @completeness="completeness"
    ref-name="worker_edu_continue"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'
import EduContinueChangForm from '../../../worker/worker/eduContinueChangForm'
import EduContinueChangList from '../../../worker/worker/eduContinueChangList'

import EduContinueRegisterForm from '../../../worker/worker/eduContinueRegisterForm'
import EduContinueRegisterList from '../../../worker/worker/eduContinueRegisterList'

import {
  workerEduContinueRequest
} from '../../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerEduContinueRequest.get({
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerEduContinueRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerEduContinueRequest.del(parm.id)
        } else {
          return await workerEduContinueRequest.update(parm.id, parm)
        }
      } else {
        return await workerEduContinueRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerEduContinueRequest.add(parm)
      } else {
        return await workerEduContinueRequest.addEdit(parm)
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_edu_continue_p'].save()
    },
    async submit () {
      await this.$refs['worker_edu_continue_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_edu_continue_p'].testEdited()
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'issueDate'
        },
        {
          type: 'input',
          label: '工作单位',
          key: 'workeOrg'
        },
        {
          type: 'input',
          label: '专业技术岗位',
          key: 'zyjsgw'
        },
        {
          type: 'input',
          label: '专业技术职务',
          key: 'zyjszw'
        },
        {
          type: 'cmp',
          label: '专业技术职务变动情况',
          formatCmp: EduContinueChangList,
          key: 'changData',
          cmp: EduContinueChangForm
        },
        {
          type: 'cmp',
          label: '继续教育登记',
          formatCmp: EduContinueRegisterList,
          key: 'registerData',
          cmp: EduContinueRegisterForm
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '证书编号',
          field: 'zsCode',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '发证日期',
          field: 'issueDate',
          sort: true,
          dataType: Date,
          filterRange: true,
          format: (rowData) => {
            return rowData && rowData.issueDate ? rowData.issueDate.slice(0, 10) : '-'
          }
        },
        {
          title: '工作单位',
          field: 'workeOrg',
          sort: true
        },
        {
          title: '专业技术岗位',
          field: 'zyjsgw',
          sort: true
        },
        {
          title: '专业技术职务',
          field: 'zyjszw',
          sort: true
        }]
        return data
      }
    }
  }
}
</script>
