import { render, staticRenderFns } from "./eduContinueRegisterList.vue?vue&type=template&id=3d58de08&scoped=true&"
import script from "./eduContinueRegisterList.vue?vue&type=script&lang=js&"
export * from "./eduContinueRegisterList.vue?vue&type=script&lang=js&"
import style0 from "./eduContinueRegisterList.vue?vue&type=style&index=0&id=3d58de08&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d58de08",
  null
  
)

export default component.exports