<template>
  <modify-apply-block
    :column-list="columnList"
    ref="worker_certificate_doctor_pra_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    :table-actions="tableActions"
    :dataFileParm="{pDirPath: '/worker_certificate/'}"
    :isReal="isReal"
    block-name="执业证书"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :onChange="onChange"
    :init-add-form-data="initAddFormData"
    @completeness="completeness"
    ref-name="worker_certificate_doctor_pra"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import CertiticateChangeLogForm from '../../../worker/worker/certiticateChangeLogForm'
import CertiticateChangeLogList from '../../../worker/worker/certiticateChangeLogList'

import {
  workerCertificateRequest
} from '../../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    allData: {
      type: Object
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      openDialogLog: false,
      workerCertificateId: null
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerCertificateRequest.get({
          type: 'doctor_pra',
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerCertificateRequest.getEdit({
          type: 'doctor_pra',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerCertificateRequest.del(parm.id)
        } else {
          return await workerCertificateRequest.update(parm.id, parm)
        }
      } else {
        return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerCertificateRequest.add(parm)
      } else {
        return await workerCertificateRequest.addEdit(parm)
      }
    },
    onChange (key, value, formData) {
      let data = {}
      if (key === 'getDate' && !formData['hireStartDate']) {
        data['hireStartDate'] = value
      }
      if (key === 'updateLog') {
        let datas = value ? JSON.parse(value) : []
        datas = datas.filter(v => ['goon', 'first'].includes(v.type))
        if (datas.length === 0) {
          data['hireStartDate'] = formData['getDate']
          data['hireEndDate'] = null
        } else {
          data['hireStartDate'] = datas[0].registDate
          data['hireEndDate'] = datas[0].registEndDate
        }
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd'})
        }
      }
      return data
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'doctor_pra'
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_certificate_doctor_pra_p'].save()
    },
    async submit () {
      await this.$refs['worker_certificate_doctor_pra_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_certificate_doctor_pra_p'].testEdited()
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['practicing_certificate_type'],
          label: '执业证书类型',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'getDate',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '有效期开始时间',
          key: 'hireStartDate',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '有效期结束时间',
          key: 'hireEndDate',
          fmDisabled: true
        },
        {
          type: 'input',
          label: '执业地点',
          key: 'practiceAddress'
        },
        {
          type: 'input',
          label: '执业类别',
          key: 'practiceType'
        },
        {
          type: 'input',
          label: '执业范围',
          key: 'practiceRange'
        },
        {
          type: 'cmp',
          label: '变更记录',
          formatCmp: CertiticateChangeLogList,
          key: 'updateLog',
          cmp: CertiticateChangeLogForm
        }]
        return data
      }
    },
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    columnList () {
      return [{
        title: '执业证书类型',
        field: 'mType',
        sort: true
      },
      {
        title: '证书编码',
        field: 'code',
        sort: true
      },
      {
        title: '发证机关',
        field: 'issueOrg',
        sort: true
      },
      {
        title: '签发人',
        field: 'issueUser',
        sort: true,
        width: 100
      },
      {
        title: '发证日期',
        field: 'getDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
        }
      },
      {
        title: '有效期开始时间',
        field: 'hireStartDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '有效期结束时间',
        field: 'hireEndDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
        }
      },
      {
        title: '执业地点',
        field: 'practiceAddress',
        sort: true
      },
      {
        title: '执业类别',
        field: 'practiceType',
        sort: true
      },
      {
        title: '执业范围',
        field: 'practiceRange',
        sort: true
      },
      {
        title: '变更记录',
        field: 'updateLog',
        format: (rowData) => {
          let dataList = rowData.updateLog ? JSON.parse(rowData.updateLog) : []
          dataList = dataList.filter(v => v && ['change', 'goon', 'first'].includes(v.type))
          return dataList.length > 0 ? dataList.length + '条记录' : '无记录'
        },
        formatValue: (rowData) => {
          return rowData.updateLog
        }
      }]
    }
  }
}
</script>
