<template>
  <div class="xp-dev">
    <a :class="wrapClass" class="assets-type-form" href="javascript:;">{{valueLabel}}
      <fm-btn size="small" style="margin-left: 2rem" @click="modalAssetsType = true">详细</fm-btn>
    </a>
    <fm-modal v-model="modalAssetsType" v-if="modalAssetsType" width="800px" theme="mh-blackt">
      <div style="width: 100%;height:70vh;">
        <fm-title title-text="续聘记录" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'add', label: '新增'}]"></fm-title>
        <fm-table-new
          :simple-filter="true"
          export-file-name="专业技术职务聘任证书续聘记录"
          :columns="columnsXp"
          border="row"
          :data-list="updateLogList"
          :stripe="false"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActionsXp" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="续聘记录"
      :open-dialog.sync="openDialog"
      :form-parms="formParmsAdd.filter(v => ['xlmc', 'mType', 'hirePro', 'hireWorker', 'hireStartDate', 'hireEndDate'].includes(v.key))"
      :old-data="hisLogData"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'ChangeLogForm',
  components: {
    TableActions
  },
  data () {
    return {
      openDialog: false,
      modalAssetsType: false,
      onError: false,
      hisLogData: {},
      updateLogList: []
    }
  },
  created() {
    this.$store.dispatch('loadJobTitleList')
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    jobTitleList () {
      return this.$store.getters.jobTitleList
    },
    tableActionsXp () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    columnsXp () {
      return [{
        title: '专业',
        field: 'hirePro'
      },
      {
        title: '聘任职务',
        field: 'hireWorker'
      },
      {
        title: '岗位类型',
        field: 'xlmc'
      },
      {
        title: '岗位等级',
        field: 'mType'
      },
      {
        title: '聘期起始日期',
        field: 'hireStartDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
        }
      },
      {
        title: '聘期终止日期',
        field: 'hireEndDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        slot: 'actions',
        search: false,
        export: false,
        configurable: false
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '专业',
          key: 'hirePro'
        },
        {
          type: 'input',
          label: '聘任单位',
          key: 'hireOrg'
        },
        {
          type: 'input',
          label: '聘任职务',
          key: 'hireWorker'
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.jobTitleTypes,
          key: 'xlmc'
        },
        {
          type: 'select',
          label: '岗位等级',
          getSelectDatas: (fData) => {
            let data = []
            if (fData && fData.xlmc) {
              data = this.jobTitleList.filter(v => v.data.type === fData.xlmc).map((v) => {
                return {
                  key: v.data.title,
                  label: v.data.title
                }
              })
            }
            return data
          },
          key: 'mType'
        },
        {
          type: 'datePicker',
          label: '聘期起始日期',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        }]
        return data
      }
    },
    valueLabel () {
      let text = '无记录'
      if (this.updateLogList.length > 0) {
        text = this.updateLogList.length + '条记录'
      }
      return text
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  watch: {
    value: {
      handler (data) {
        this.updateLogList = data ? JSON.parse(data) : []
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async formSubmit (parm, resolve) {
      for (let key in parm) {
        if (parm[key] instanceof Date) {
          parm[key] = dateOperating.computeDay({days: 0, date: parm[key], format: 'yy-mm-dd'})
        }
      }
      let newData = []
      if (parm.id) {
        this.updateLogList.forEach((v) => {
          if (v.id === parm.id) {
            newData.push(parm)
          } else {
            newData.push(v)
          }
        })
      } else {
        newData = this.updateLogList.map(v => v)
        parm.id = new Date().getTime()
        newData.push(parm)
      }
      newData.sort((a, b) => {
        let datea = new Date(a.registDate || a.changeDate)
        let dateb = new Date(b.registDate || b.changeDate)
        return dateb - datea
      })
      this.change(JSON.stringify(newData))
      this.openDialog = false
      resolve()
    },
    clickTitleMenu () {
      this.hisLogData = null
      this.openDialog = true
    },
    async tableAction (parm) {
      console.log(parm)
      let key = parm.action
      let data = parm.data
      if (key === 'edit') {
        this.hisLogData = Object.assign({}, data)
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该续聘记录吗？'})
        if (!result) {
          return
        }
        this.change(JSON.stringify(this.updateLogList.filter(v => v.id !== data.id)))
      }
    },
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    change (data) {
      this.$emit('change', data)
      this.verifier()
    }
  }
}
</script>

<style lang="less">
.xp-dev {
  .fm-form-item {
    width: 100% !important;
  }
}
</style>

<style lang="less" scope>
.assets-type-form-dev {
  // 报错设置
    .verifier-error {
      border-color: red;
    }
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.zyzs-bjjl {
  height: calc(100% - 3rem);
  overflow-y: auto;
}
.log-info-t {
  color: #515a6e;
}
</style>