<template>
  <div class="lable-i-group theme-lable-i-group">
    <div class="label-i" style="margin-right: 3px" :key="item" v-for="item in statusList">{{item}}</div>
  </div>
</template>

<script>
export default {
  props: {
    dataStatus: {
      type: Object
    }
  },
  data () {
    return {
      typeMap: {
        edit: '改',
        del: '删',
        add: '增'
      },
      statusMap: {
        edit: '存',
        modifyApply: '提'
      }
    }
  },
  computed: {
    statusList () {
      return Object.keys(this.dataStatus).map(v => (this.statusMap[v] || '') + (this.typeMap[this.dataStatus[v]] || ''))
    }
  }
}
</script>

<style lang="less" scope>
.lable-i-group {
  min-width: 90px;
  display: flex;
  align-items: center;
}
.label-i {
  height: 18px;
  padding: 0 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F4628F;
  border-radius: 9px;
  color: #fff;
  font-size: 12px;
}
</style>

<style lang="less">
  .pink-theme {
    .theme-lable-i-group {
      .label-i {
        background: #F4628F;
      }
    }
  }
  .blue-theme {
    .theme-lable-i-group {
      .label-i {
        background: #2F5BEA;
      }
    }
  }
  .yellow-theme {
    .theme-lable-i-group {
      .label-i {
        background: #6C573C;
      }
    }
  }
</style>