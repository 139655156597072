<template>
  <div class="zyzs-bjjl">
    <div v-for="(item, index) in dataList" :key="index">
      <div class="jl-item">
        <div class="log-info">
          <div>专业技术职务名称:</div>
          <span>{{item.hirePro}}</span>
        </div>
        <div class="log-info">
          <div>任职开始时间:</div>
          <span>{{item.startDate}}</span>
        </div>
        <div class="log-info">
          <div>任职结束时间:</div>
          <span>{{item.endDate}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  computed: {
    dataList () {
      return this.value ? JSON.parse(this.value) : []
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  width: 16rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}
.log-info {
  display: flex;
  min-height: 1.4rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>