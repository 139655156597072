<template>
  <div class="steps theme-step">
    <div class="step-item"
      :key="item.key"
      :class="{active: cStep === item.key}"
      v-for="(item, index) in steps">
      <div class="t-tag">
        <!-- <div class="left-l" :class="{'hind-l': index === 0, 'a-l': (item.status === 'ok' || cStep === item.key) && steps[index - 1] && steps[index - 1].status === 'ok'}"></div> -->
        <div class="left-l" :class="{'hind-l': index === 0, 'a-l': index !== 0}"></div>
        <!-- <a href="javascript:;" class="di" @click="chooseStep(index)" :class="{'active-di': item.status === 'ok', 'c-dian': cStep === item.key}"> <span v-if="item.status === 'ok' || cStep === item.key">{{cStep === item.key ? index + 1 : '✔'}}</span></a> -->
        <circle-dom :size="28" @clickDom="chooseStep(index)" :border-width="2" :rate="completeness[item.key] || 0"></circle-dom>
        <!-- <div class="right-l" :class="{'hind-l': index === steps.length - 1, 'a-l': item.status === 'ok' && steps[index + 1] && (steps[index + 1].status === 'ok' || cStep === steps[index + 1].key)}"></div> -->
        <div class="right-l" :class="{'hind-l': index === steps.length - 1, 'a-l': index !== steps.length - 1}"></div>
      </div>
      <div class="label" :class="{'label-a': item.status === 'ok' || cStep === item.key}">
        {{item.label}}
      </div>
    </div>
  </div>
</template>

<script>
import CircleDom from './circleDom'
export default {
  components: {CircleDom},
  props: {
    cStep: {
      type: String
    },
    steps: {
      type: Array
    },
    completeness: {
      type: Object, default () {
        return {}
      }
    }
  },
  methods: {
    chooseStep (i) {
      this.$emit('setStep', i)
    }
  }
}
</script>

<style lang="less" scoped>
@color-no:#B2B2B2;
@color-a:#F4628F;
.steps {
  display: flex;
  .step-item {
    flex: 1;
    &.active {
      .label, .label-a {
        color: @color-a;
      }
    }
    .t-tag {
      display: flex;
      align-items: center;
      .left-l {
        flex: 1;
        height: 2px;
        background: @color-no;
      }
      .di {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid @color-no;
      }
      .active-di {
        border: 2px solid @color-a;
        color: @color-a;
      }
      .right-l {
        flex: 1;
        height: 2px;
        background: @color-no;
      }
      .hind-l {
        background: rgba(0,0,0,0);
      }
      .a-l {
        background: @color-a;
      }
      .c-dian {
        background: @color-a;
        border: 2px solid @color-a;
        color: #fff;
      }
    }
    .label {
      text-align: center;
      margin: 10px;
      color: @color-no;
      font-size: 14px;
    }
    .label-a {
      color: #4A4A4A;
    }
  }
}
</style>

<style lang="less">
@color-no:#B2B2B2;
@color-a:#F4628F;
@color-b:#2F5BEA;
@color-c:#6C573C;
.pink-theme {
  .theme-step {
    .step-item {
      &.active {
        .label, .label-a {
          color: @color-a;
        }
      }
      .t-tag {
        .left-l {
          background: @color-no;
        }
        .di {
          border: 2px solid @color-no;
        }
        .active-di {
          border: 2px solid @color-a;
          color: @color-a;
        }
        .right-l {
          background: @color-no;
        }
        .hind-l {
          background: rgba(0,0,0,0);
        }
        .a-l {
          background: @color-a;
        }
        .c-dian {
          background: @color-a;
          border: 2px solid @color-a;
          color: #fff;
        }
      }
      .label {
        color: @color-no;
      }
      .label-a {
        color: #4A4A4A;
      }
    }
  }
}
.blue-theme {
  .theme-step {
    .step-item {
      &.active {
        .label, .label-a {
          color: @color-b;
        }
      }
      .t-tag {
        .left-l {
          background: @color-no;
        }
        .di {
          border: 2px solid @color-no;
        }
        .active-di {
          border: 2px solid @color-b;
          color: @color-b;
        }
        .right-l {
          background: @color-no;
        }
        .hind-l {
          background: rgba(0,0,0,0);
        }
        .a-l {
          background: @color-b;
        }
        .c-dian {
          background: @color-b;
          border: 2px solid @color-b;
          color: #fff;
        }
      }
      .label {
        color: @color-no;
      }
      .label-a {
        color: #4A4A4A;
      }
    }
  }
}
.yellow-theme {
  .theme-step {
    .step-item {
      &.active {
        .label, .label-a {
          color: @color-c;
        }
      }
      .t-tag {
        .left-l {
          background: @color-no;
        }
        .di {
          border: 2px solid @color-no;
        }
        .active-di {
          border: 2px solid @color-c;
          color: @color-c;
        }
        .right-l {
          background: @color-no;
        }
        .hind-l {
          background: rgba(0,0,0,0);
        }
        .a-l {
          background: @color-c;
        }
        .c-dian {
          background: @color-c;
          border: 2px solid @color-c;
          color: #fff;
        }
      }
      .label {
        color: @color-no;
      }
      .label-a {
        color: #4A4A4A;
      }
    }
  }
}
</style>