<template>
  <div class="zyzs-bjjl">
    <div v-for="(item, index) in updateLogList" :key="index">
      <div class="jl-item">
        <div class="log-info">
          <div>专业:</div>
          <span>{{item.hirePro}}</span>
        </div>
        <div class="log-info">
          <div>聘任职务:</div>
          <span>{{item.hireWorker}}</span>
        </div>
        <div class="log-info">
          <div>岗位类型:</div>
          <span>{{item.xlmc}}</span>
        </div>
        <div class="log-info">
          <div>岗位等级:</div>
          <span>{{item.mType}}</span>
        </div>
        <div class="log-info">
          <div>聘期起始日期:</div>
          <span>{{item.hireStartDate}}</span>
        </div>
        <div class="log-info">
          <div>聘期终止日期:</div>
          <span>{{item.hireEndDate}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  computed: {
    updateLogList () {
      console.log("updateLogList", this.value ? JSON.parse(this.value) : [])
      return this.value ? JSON.parse(this.value) : []
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  width: 16rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}
.log-info {
  display: flex;
  min-height: 1.4rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>